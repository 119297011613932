let config = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "forwood-macquarie-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-macquarie-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "eu-west-1",
        URL: "https://kbcf6cj36ze6tfhvyxhtjvfnfm.appsync-api.eu-west-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://igva3pmt8b.execute-api.eu-west-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://ekts4ykuf7.execute-api.eu-west-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.macquarie.forwoodsafety.com",
        WEBSOCKET: "wss://nycrtszsrd.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_TmKudpTMn",
        IDENTITY_POOL_ID: "eu-west-1:f75f1118-1c49-4217-97fc-8af192f35640",
        USERPOOL_HOSTED_DOMAIN: "forwood-macquarie-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.macquarie.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.macquarie.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::069111662938:role/prod-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure,fiberhost'
    },
    reactApp: {
        VERSION: "1.36.2",
        HOSTNAME: "id.macquarie.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".macquarie.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.macquarie.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
